import axios from 'axios'
import * as commondef from './common'

import router from '../router'
import store from '../vuex/store'
export const axioInstance = axios.create({
  baseURL: commondef._baseUrlThird
})

axioInstance.interceptors.request.use(function (config) {
  config.headers['Content-Type'] = 'application/json'
  return config
}, function (error) {
  return Promise.reject(error)
})

export const axioForm = axios.create({
  baseURL: commondef._baseUrlThird
})

axioForm.interceptors.request.use(function (config) {
  config.headers['Content-Type'] = 'multipart/form-data'
  return config
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
})

var accessToken = null
let getRes = function (response) {
  if (response.status >= 400) {
    router.push({
      path: '/error_page',
      params: {
        error_code: response.data.code,
        page_path: location.hash.replace('#', '')
      }
    })
    return
  }
  if (response.status >= 200 && response.status < 300) {
    var res = response.data
    // console.log(`拦截res ${JSON.stringify(res)}`);
    if (!!res && res.code === commondef.resCode.Unauthorized) {
      if (window.location.hash.indexOf('/login') === -1) {
        localStorage.removeItem(commondef.keyAccessToken)
        router.replace({path: '/login'})
        return res
      }
    } else if (response.config.url.indexOf('/login') > 0) {
      // console.log('response.data:', response.data)
      accessToken = response.data.access_token
      if (accessToken) {
        localStorage.setItem(commondef.keyAccessToken, accessToken)
      }
    } else if (res && res.code == 80014) {
      store.commit('commitOnline', false)
    }
    return res
  } else {
    return { status: response.status, code: Number.MAX_VALUE, msg: '' }
  }
  // return response;
}
let handleError = function (error) {
  // 处理响应失败
  router.push({
    name: 'error_page',
    params: {
      error_code: null,
      page_path: location.hash.replace('#', '')
    }
  })
  return Promise.reject(error)
}

axioInstance.interceptors.response.use(getRes, handleError)

axioForm.interceptors.response.use(getRes, handleError)

accessToken = null
export const getPublicPara = function (version) {
  return {
    version: version || 'v2',
    app_key: '666a659e-244d-46b1-a8cc-3c5190c3823a',
    app_pass: 'd410fca1-7b0a-4522-afca-b3650af14cae',
    access_token: localStorage.getItem(commondef.keyAccessToken)
  }
}

export const axiosRequest = (url, para, form, istocken) => {
  // form-data上传时form传true, istocken：true为不需要tocken
  let params = getPublicPara(para.version)
  if (istocken) {
    delete params.access_token
  }
  if (para) {
    for (let item in para) {
      if (!para.hasOwnProperty(item) || item === commondef.keyAccessToken) {
        continue
      }
      if (para[item] || para[item] === 0 || para[item] === false) {
        params[item] = para[item]
      }
    }
  }
  params.uid = get_req_uid ()
  if (form) {
    let obj = new FormData()
    obj.append('json', JSON.stringify(params))
    obj.append('file', params.file)
    return axioForm.post(url, obj)
  } else {
    return axioInstance.post(url, params)
  }
}

export const axiosRequests = (url, para, form, istocken) => {
  // form-data上传时form传true, istocken：true为不需要tocken
  let params = getPublicPara()
  if (istocken) {
    delete params.access_token
  }
  if (para) {
    for (let item in para) {
      if (!para.hasOwnProperty(item) || item === commondef.keyAccessToken) {
        continue
      }
      if (para[item] || para[item] === 0 || para[item] === false || para[item] === '') {
        params[item] = para[item]
      }
    }
  }
  params.uid = get_req_uid ()
  if (form) {
    let obj = new FormData()
    obj.append('json', JSON.stringify(params))
    obj.append('file', params.file)
    return axioForm.post(url, obj)
  } else {
    return axioInstance.post(url, params)
  }
}

export const urlList = {
  login: 'login',
  fenglin: {
    query: {
      query_charge_info: 'fenglin.query.query_charge_info',
      query_charge_list: 'fenglin.query.query_charge_list',
      query_inout_list: 'fenglin.query.query_inout_list',
      query_inout_detail: 'fenglin.query.query_inout_detail'
    },
    vh: {
      add: 'fenglin.vh.add'
    },
    open_gate: {
      vh_inout_pnull_in: 'fenglin.open_gate.vh_inout_pnull_in',
      remote_open_gate: 'fenglin.open_gate.remote_open_gate'
    },
    park: {
      get_online_status: 'fenglin.park.get_online_status'
    }
  },

  park: {
    get_device: 'park.get_device'  // version: 'v1'
  },
  remote: {
    get_list: 'remote.get_list' // version: 'v1'
  }
}



export const axioApiInstance = axios.create({
  baseURL: commondef._baseUrlApi
})

export const axioApiForm = axios.create({
  baseURL: commondef._baseUrlApi
})

axioApiForm.interceptors.response.use(getRes, handleError)

axioApiInstance.interceptors.response.use(getRes, handleError)

export const axiosApiRequests = (url, para, form, istocken) => {
  // form-data上传时form传true, istocken：true为不需要tocken
  let params = getPublicPara()
  if (istocken) {
    delete params.access_token
  }
  if (para) {
    for (let item in para) {
      if (!para.hasOwnProperty(item) || item === commondef.keyAccessToken) {
        continue
      }
      if (para[item] || para[item] === 0 || para[item] === false || para[item] === '') {
        params[item] = para[item]
      }
    }
  }
  params.uid = get_req_uid ()
  if (form) {
    let obj = new FormData()
    obj.append('json', JSON.stringify(params))
    obj.append('file', params.file)
    return axioApiForm.post(url, obj)
  } else {
    return axioApiInstance.post(url, params)
  }
}




export const apiUrlList = {
  reg: 'reg',
  login: 'login',
  wx_login: 'wx_login',
  gzh: {
    get_self_park_list: 'gzh.get_self_park_list'
  },
  platform: {
    user: {
      relevance_park: 'platform.user.relevance_park',
      logout: 'platform.user.logout',
      reset_password: 'platform.user.reset_password',
      bind_wx: 'platform.user.bind_wx',
      get_user_menu: 'platform.user.get_user_menu'
    }
  },
  vh_black: {
    add_black_vh: 'vh_black.add_black_vh'
  },
  park: {
    get_device: 'park.get_device'
  }
}

function guid() {
  return 'xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  });
}
function get_req_uid () {
  if (sessionStorage.getItem('request_remote_uid')) {
    return sessionStorage.getItem('request_remote_uid')
  } else {
    let uid = guid()
    sessionStorage.setItem('request_remote_uid', uid)
    return uid
  }
}

export const getQuery = function () {
  let query = this.$route.query
  if (query && query.user_type_name == commondef.userTypesTxt.owner) {
    return query
  }
  return {}
}
