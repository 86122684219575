export const _baseUrl = window.location.origin
export const parkCloudQLUrl = _baseUrl
export const mqttUrl = 'parkcloud.szzcloud.club'
export const payCloud = 'http://paycloud.szzcloud.club'

// export const mqttUrl = 'parkcloud.test.szzcloud.club'
// export const payCloud = 'http://paycloud.test.szzcloud.club'


// // test

// export const _baseUrl = 'http://charge.szzcloud.club'
// export const parkCloudQLUrl = 'http://charge.szzcloud.club'
// // teststest
// export const parkCloudQLUrl = 'http://192.168.0.168:7880'
// export const _baseUrl = 'http://192.168.0.168:7880'
// export const mqttUrl = 'parkcloud.szzcloud.club'
// // // test
export const _baseUrlThird = _baseUrl + '/third'
export const _baseUrlApi = _baseUrl + '/api'
export const keyAccessToken = 'accessToken'
export const keyUserInfo = 'userInfo'
export const globlePagesize = 10
import * as showMenu  from './show_menu'
export const cleanLoginInfo = () => {
  localStorage.removeItem(keyAccessToken)
}

export const setUserInfo = info => {
  localStorage.setItem(keyUserInfo, JSON.stringify(info))
}

export const getUserInfo = () => {
  var infostr = localStorage.getItem(keyUserInfo)
  return JSON.parse(infostr)
}

export const resCode = {
  OK: 10000,
  DataExists: 10001,
  KeyStillReferenced: 10002,
  Unauthorized: 11000,
  Forbidden: 11001,
  UsernameorPasswordError: 11002,
  UserBeLocked: 11003,
  InternalServerError: 11100,
  PostgresqlError: 11101,
  RedisError: 11102,
  ApiParamsError: 11103,
  ResourceNotbeFound: 11104,
  InterfaceDisabled: 11105,
  Failed: 99999
}

export const resMSG = {
  '10001': '数据重复',
  '10002': '外键冲突',
  '11000': '请重新登录',
  '11001': '没有权限',
  '11002': '请核对登录信息',
  '11003': '用户已锁定',
  '11100': '内部错误',
  '11101': 'Postgre错误',
  '11102': 'Redis错误',
  '11103': 'API参数错误',
  '11104': '资源未找到',
  '11105': '接口废弃',
  '99999': '未知错误'
}

export const editWord = {
  addSuccess: '添加成功',
  addError: '添加失败',
  modifySuccess: '修改成功',
  modifyError: '修改失败',
  deleteSuccess: '删除成功',
  deleteError: '删除失败'
}

export const fmtDate = function (oDate, format) {
  // fmtDate('2017/12/12 09:08:08', 'yyyy-MM-dd hh:mm:ss')
  if (oDate == '' || oDate == null) {
    format = ''
  } else {
    if (/^\d{2,4}-\d{1,2}-\d{1,2}/.test(oDate)) {
      oDate = oDate.replace(/-/g, '/').replace(/T/, ' ').replace(/\.\d+Z$/, '+00')
    }
    if (/^\d{13}/.test(oDate)) {
      oDate = parseFloat(oDate)
    }
    let thisdate = new Date(oDate)
    let date = {
      'M+': thisdate.getMonth() + 1,
      'd+': thisdate.getDate(),
      'h+': thisdate.getHours(),
      'm+': thisdate.getMinutes(),
      's+': thisdate.getSeconds(),
      'q+': Math.floor((thisdate.getMonth() + 3) / 3),
      'S+': thisdate.getMilliseconds()
    }
    if (/(y+)/i.test(format)) {
      format = format.replace(
        RegExp.$1,
        (thisdate.getFullYear() + '').substr(4 - RegExp.$1.length)
      )
    }
    for (let k in date) {
      if (new RegExp('(' + k + ')').test(format)) {
        format = format.replace(
          RegExp.$1,
          RegExp.$1.length === 1
            ? date[k]
            : ('00' + date[k]).substr(('' + date[k]).length)
        )
      }
    }
  }
  return format
}

// 翻页设置
export const page_option = {
  page_size: 10,
  page_sizes: [10, 20, 30, 40, 50],
  page_no: 1,
  total: 0
}

export const userTypesTxt = showMenu.userTypesTxt

export const userTypes = showMenu.userTypes

export const menus =  showMenu.menus

export const plateNumberHeader = [
  '京', '津', '渝', '沪', '冀', '晋', '辽', '吉', '黑', '苏',
  '浙', '皖', '闽', '赣', '鲁', '豫', '鄂', '湘', '粤', '琼',
  '川', '贵', '云', '陕', '甘', '青', '蒙', '桂', '宁', '新',
  '藏', '使', '领', '警', '学', '港', '澳'
]

export const saveHistoryPlateNumber = function (plateNumber) {
  let history = []
  let localHistory = window.localStorage.getItem('historyPlateNumber')
  if (localHistory) {
    history = JSON.parse(localHistory)
  }
  if (!history.includes(plateNumber)) history.unshift(plateNumber)
  if (history.length > 10) {
    history.pop()
  }
  window.localStorage.setItem('historyPlateNumber', JSON.stringify(history))
  window.localStorage.setItem('latestHistoryPlateNumber', plateNumber)
  return history
}

export const getHistoryPlateNumber = function () {
  let localHistory = window.localStorage.getItem('historyPlateNumber')
  if (localHistory) {
    return JSON.parse(localHistory)
  }
  return []
}

export const clearHistoryPlateNumber = function () {
  window.localStorage.removeItem('historyPlateNumber')
  return []
}


export const saveHistoryGroupName = function (name) {
  let history = []
  let localHistory = window.localStorage.getItem('historyGroupName')
  if (localHistory) {
    history = JSON.parse(localHistory)
  }
  if (!history.includes(name)) history.unshift(name)
  if (history.length > 10) {
    history.pop()
  }
  window.localStorage.setItem('historyGroupName', JSON.stringify(history))
  window.localStorage.setItem('latestHistoryGroupName', name)
  return history
}

export const getHistoryGroupName = function () {
  let localHistory = window.localStorage.getItem('historyGroupName')
  if (localHistory) {
    return JSON.parse(localHistory)
  }
  return []
}

export const clearHistoryGroupName = function () {
  window.localStorage.removeItem('historyGroupName')
  return []
}


export const saveHistoryStorePlate = function (plateNumber) {
  let history = []
  let localHistory = window.localStorage.getItem('historyStorePlate')
  if (localHistory) {
    history = JSON.parse(localHistory)
  }
  if (!history.includes(plateNumber)) history.unshift(plateNumber)
  if (history.length > 10) {
    history.pop()
  }
  window.localStorage.setItem('historyStorePlate', JSON.stringify(history))
  window.localStorage.setItem('latestHistoryPlateNumber', plateNumber)
  return history
}

export const getHistoryStorePlate = function () {
  let localHistory = window.localStorage.getItem('historyStorePlate')
  if (localHistory) {
    return JSON.parse(localHistory)
  }
  return []
}

export const clearHistoryStorePlate = function () {
  window.localStorage.removeItem('historyStorePlate')
  return []
}


export const showErrorMessage = function (page, e) {
  if (typeof e === 'object') {
    page.$toast(e.msg || e.message || '请求出错')
  } else if (typeof e === 'string') {
    page.$toast(e)
  } else {
    page.$toast('请求出错')
  }
}

export const chargeType = {
  rent: 1,
  group: 2,
  store: 3
}

export const vh_cate = {
  rent: 1,
  tem: 2,
  store: 3,
  group: 4
}
export const getProp = (variable) => {
  if(window.location.toString().indexOf('?') == -1){
    return(false);
  }
  var url = decodeURI(decodeURI(window.location)).toString().split('?')[1];
  var row = url.split("&");
  for (var i=0;i<row.length;i++) {
    var pair = row[i].split("=");
    if(pair[0] == variable){
      if(pair[1] == 'null'){
        return ''
      }else{
        return pair[1]
      }
    }
  }
  return(false);
}

export const getQueryUserInfo = function () {
  let sid = getProp('sid') || localStorage.getItem('sid')
  localStorage.setItem('sid', sid)
  let localUserInfo = localStorage.getItem('userInfo')
  if (localUserInfo && /^{.*}$/.test(localUserInfo)) {
    localUserInfo = JSON.parse(localUserInfo)
  }
  let u_t = getProp('user_type_name')
  if (u_t && decodeURIComponent(decodeURIComponent(u_t)) == userTypesTxt.owner) {
    let parkName, userTypeName, account, showToday, showProlong
    if (getProp('sid')) {
      localStorage.setItem('sid', getProp('sid'))
    }
    if (getProp('park_name')) {
      parkName = decodeURIComponent(decodeURIComponent(getProp('park_name')))
    }
    if (getProp('user_type_name')) {
      userTypeName = decodeURIComponent(decodeURIComponent(getProp('user_type_name')))
      localStorage.removeItem(keyAccessToken)
    }
    if (getProp('account')) {
      account = decodeURIComponent(decodeURIComponent(getProp('account')))
    }
    if (getProp('show_today')) {
      showToday = getProp('show_today')
    }
    if (getProp('show_prolong')) {
      showProlong = getProp('show_prolong')
    }

    let userInfo = {
      parkName: parkName || (localUserInfo && localUserInfo.parkName),
      userTypeName: userTypeName || (localUserInfo && localUserInfo.userTypeName),
      account:  account || (localUserInfo && localUserInfo.account),
      // showToday: showToday
    }
    if (showProlong) {
      userInfo.showProlong = showProlong
    }
    if(showToday != undefined){
      if (showToday == false || showToday == 'false') {
        userInfo.showToday = 0
      } else {
        userInfo.showToday = 1
      }
    }

    localStorage.setItem('userInfo', JSON.stringify(userInfo))
    this.$store.commit('commitUserInfo')
    return userInfo
  } else {
    this.$store.commit('commitUserInfo')
    return localUserInfo
  }
}

export const getMonthBeginEnd = function (datetime) {
  let eTime = new Date(datetime)
  let begin = new Date(eTime.getFullYear(), eTime.getMonth(), 1)
  let begin_time = fmtDate(begin, 'yyyy-MM-dd 00:00:00')
  let end = new Date(eTime.getFullYear(), eTime.getMonth() + 1, 0)
  let end_time = fmtDate(new Date(end), 'yyyy-MM-dd 23:59:59')
  return {
    begin_time,
    end_time
  }
}

export const direction = {
  in: 1,
  out: 2
}

export const translateUserType = function (type) {
  if (type == '00010001') {
    return userTypesTxt.owner
  } else if (type == '00010002') {
    return userTypesTxt.park
  } else if (type == '00010003') {
    return userTypesTxt.groupCompany
  } else if (type == '00010004') {
    return userTypesTxt.agent
  } else {
    return userTypesTxt.owner
  }
}