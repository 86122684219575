

export const userTypesTxt = {
  owner: '车主',
  // groupVhOwner: '分组车主',
  park: '车场',
  groupCompany:  '集团',
  agent: '商户'
}

export const userTypes = [
  userTypesTxt.owner,
  // userTypesTxt.groupVhOwner,
  userTypesTxt.park,
  userTypesTxt.groupCompany,
  userTypesTxt.agent
]

export const menus =  [
  {
    released: true,
    img: './menu_img/charge-menu.png',
    path: '/recharge_prolong',
    txt: '充值延期',
    can: [
      userTypesTxt.owner,
      // userTypesTxt.groupVhOwner,
      userTypesTxt.park,
      userTypesTxt.groupCompany
    ]
  },
  {
    released: true,
    img: './menu_img/add_vh.png',
    txt: '增固定车',
    path: '/add_vh',
    can: [
      userTypesTxt.park,
      userTypesTxt.groupCompany
    ]
  },
  {
    released: true,
    img: './menu_img/black-add-menu.png',
    txt: '增黑名单',
    path: '/add_black',
    can: [
      userTypesTxt.park,
      userTypesTxt.groupCompany
    ]
  },
  // {
  //   released: false,
  //   img: '',
  //   txt: '停车缴费',
  //   can: [
  //     userTypesTxt.owner,
  //     userTypesTxt.groupVhOwner,
  //     userTypesTxt.park
  //   ]
  // },
  {
    released: false,
    img: './menu_img/guest-appoint-menu.png',
    txt: '访客预约',
    path: '/guest_appoint',
    can: [
      userTypesTxt.park,
      userTypesTxt.groupCompany
    ]
  },
  {
    released: true,
    img: './menu_img/inout.png',
    path: '/inout_record',
    txt: '出入查询',
    can: [
      userTypesTxt.owner,
      userTypesTxt.park,
      userTypesTxt.groupCompany
    ]
  },
  {
    released: true,
    img: './menu_img/remote-open-gate.png',
    txt: '远程开闸',
    path: '/remote_open_gate',
    can: [
      // userTypesTxt.owner,
      // userTypesTxt.groupVhOwner,
      userTypesTxt.park,
      userTypesTxt.groupCompany
    ]
  },
  // {
  //   released: false,
  //   img: '',
  //   txt: '统计信息',
  //   can: [
  //     userTypesTxt.park,
  //     userTypesTxt.groupCompany
  //   ]
  // },
  // {
  //   released: false,
  //   img: '',
  //   txt: '收费规则',
  //   can: [
  //     userTypesTxt.park
  //   ]
  // },
  // {
  //   released: false,
  //   img: '',
  //   txt: '通道管理',
  //   can: [
  //     userTypesTxt.park
  //   ]
  // },
  // {
  //   released: false,
  //   img: '',
  //   txt: '出入场记录',
  //   can: [
  //     userTypesTxt.owner,
  //     userTypesTxt.groupVhOwner
  //   ]
  // },
  {
    released: true,
    path: '/recharge_record',
    img: './menu_img/charge-list-menu.png',
    txt: '充值记录',
    can: [
      // userTypesTxt.owner,
      // userTypesTxt.groupVhOwner
      userTypesTxt.owner,
      userTypesTxt.groupVhOwner,
      userTypesTxt.park,
      userTypesTxt.groupCompany
    ]
  },
  // {
  //   released: false,
  //   img: '',
  //   txt: '发放优惠券',
  //   can: [
  //     userTypesTxt.agent
  //   ]
  // },
  // {
  //   released: false,
  //   img: '',
  //   txt: '购买优惠券',
  //   can: [
  //     userTypesTxt.agent
  //   ]
  // },
  // {
  //   released: false,
  //   img: '',
  //   txt: '发放记录',
  //   can: [
  //     userTypesTxt.agent
  //   ]
  // }
]
